import React, { useEffect, useState } from "react"
import {
  ButtonsResponsive, ButtonsResponsive2, ButtonVoice, Card, Container, ContainerResponsive, ErroMensage,
  Field, FieldResponsive, Forget, Form, FormResponsive, Heading, HeadingResponsive, Icon, InputDiv,
} from "./style"
import idCardImage from "../../assets/images/proviso.jpg"
import Button from "../../Components/Button"
import Input from "../../Components/Input"
import api from "../../Services/api"
import { useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import EsqueciSenhaModal from "../../Components/modals/EsqueciSenha"
import ReCAPTCHA from "react-google-recaptcha";
import { TiMicrophone } from "react-icons/ti";
import axios from "axios"
import EscolhaAcesso from "../../Components/modals/Login/EscolhaAcesso"
import { Alert } from "../../utils/Alert"
import { FaRegQuestionCircle } from "react-icons/fa"
import ComoAcessar from "../../Components/modals/Login/ComoAcessar"
// import ModalVoiceLogin from "../../Components/modals/VoiceLogin"

export default function Login() {
  const UpdateData = '12/09/2024';

  const [range, setRange] = useState(window.innerWidth)
  const breakpoint = 1050
  const [rm, setRm] = useState("")
  const [senha, setSenha] = useState("")
  const [capt, setCapt] = useState('')
  const [rm2, setRm2] = useState(false)
  const [senha2, setSenha2] = useState(false)
  const [capt2, setCapt2] = useState(false)
  const [Ip, setIp] = useState("");
  const [modalVoice, setModalVoice] = useState(false)
  const [Show, setShow] = useState(false)
  const [showComoAcessar, setShowComoAcessar] = useState(false)
  const [showEscolha, setShowEscolha] = useState(false)
  const [multipleAlunos, setMultipleAlunos] = useState([]);
  const Navigate = useNavigate()

  const data = { nome_usuario: rm, senha: senha, ipuser: Ip }

  useEffect(() => {
    getIpAddress();
    localStorage.clear();
    console.clear();
  }, []);

  const getIpAddress = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const { ip } = await response.json();
      setIp(ip);
    } catch (error) {
      console.error('Erro ao obter o IP:', error);
    }
  };

  useEffect(() => {
    setRm(rm);
  }, [rm, senha]);

  function HandleSingleUserResponse(resData) {
    if (resData.erro) {
      return Alert('Erro', resData.erro.split('.')[0]);
    } else {
      localStorage.setItem("token", resData.token);
      if (resData.perfil === "aluno") {
        if (resData.mensagem) {
          if (resData.mensagem.includes("Informe uma senha para o aluno.")) {
            localStorage.setItem("Entrada", "true");
          }
        }
        localStorage.setItem("token2", btoa(`${resData.aluno.id} ${resData.aluno.rm} ${resData.aluno.nome_aluno}`));
        Navigate("/aluno");
      } else if (resData.perfil == "professor") {
        Navigate("/professor-home");
      } else {
        localStorage.setItem("usuario", data.nome_usuario);
        Navigate("/secretaria");
      }
    }
  }

  function HandleSubmit(e) {
    e.preventDefault();
    if (!data.nome_usuario) {
      setRm2(true);
      setTimeout(() => {
        setRm2(false);
      }, 4000);
    } else if (!data.senha) {
      setSenha2(true);
      setTimeout(() => {
        setSenha2(false);
      }, 4000);
    } else if (!capt && !window.location.href.includes('localhost')) {
      setCapt2(true);
      setTimeout(() => {
        setCapt2(false);
      }, 4000);
    } else {
      if (data.nome_usuario.trim() !== "" && data.senha.trim() !== "") {
        api
          .post(`/entrar`, { data })
          .then(async (res) => {
            const resData = await res.data;
            if (typeof resData === 'object' && !resData.perfil && Object.keys(resData).length > 1) {
              const alunosArray = Object.values(resData);
              setMultipleAlunos(alunosArray);
              setShowEscolha(true);
            } else {
              HandleSingleUserResponse(resData); // Um único aluno ou outro tipo de usuário
            }
          })
          .catch((err) => {
            console.log(err);
            Swal.fire({
              title: "Erro",
              text: `${err.response?.data?.erro.split('.')[0] || "Erro interno do servidor"}`,
              icon: "error",
              iconColor: "red",
              confirmButtonColor: "var(--azul)",
              confirmButtonText: "Confirmar",
            });
          });
      }
    }
  }

  const handleAlunoSelection = (selectedAluno) => {
    HandleSingleUserResponse(selectedAluno);
    setShowEscolha(false);
  };

  const PROD_reCAPTCHA = '6LeJGQIqAAAAALYK6wg1y9UFu9GXFGbfES0EKbmN'
  const LOCAL_reCAPTCHA = '6Lc8EgIqAAAAAHYEGKRwCh_DPJ3P0JZqHZ-PcaBR'

  if (range < breakpoint) {
    return ( // VISÃO DE CELULAR --------------- VISÃO DE CELULAR --------------- VISÃO DE CELULAR --------------- VISÃO DE CELULAR
      <ContainerResponsive>
        {/* <ModalVoiceLogin modalVoice={modalVoice} setModalVoice={setModalVoice} rm={rm} setRm={setRm} senha={senha}setSenha={setSenha} /> */}
        <EsqueciSenhaModal Show={Show} setShow={setShow} />
        <ComoAcessar showComoAcessar={showComoAcessar} setShowComoAcessar={setShowComoAcessar} />
        <EscolhaAcesso
          showEscolha={showEscolha} setShowEscolha={setShowEscolha}
          multipleAlunos={multipleAlunos} setMultipleAlunos={setMultipleAlunos}
          handleAlunoSelect={handleAlunoSelection}
        />
        <FormResponsive onSubmit={(e) => { e.preventDefault(), HandleSubmit(e) }}>
          <HeadingResponsive>
            Acessar Carteirinha
            <p className="ComoAcessar" onClick={() => setShowComoAcessar(true)}>Primeiro Acesso <FaRegQuestionCircle /></p>
          </HeadingResponsive>
          <FieldResponsive>
            <InputDiv>
              <div style={{ display: "flex", alignItems: "end" }}>
                <Input
                  value={rm ? rm : ""}
                  width="100%"
                  color="var(--azul)"
                  placeholdercolor="var(--azul)"
                  type={"text"}
                  placeholder={"RM"}
                  required={"true"}
                  event={(e) => setRm(e.target.value)}
                />
              </div>
              {rm2 ? <ErroMensage>Campo de RM vazio</ErroMensage> : null}
            </InputDiv>
            <InputDiv>
              <div style={{ display: "flex", alignItems: "end" }}>
                <Input
                  value={senha ? senha : ""}
                  width="100%"
                  color="var(--azul)"
                  placeholdercolor="var(--azul)"
                  type={"password"}
                  placeholder={"Senha"}
                  required={"true"}
                  event={(e) => setSenha(e.target.value)}
                />
              </div>
              {senha2 ? <ErroMensage>Campo de Senha vazia</ErroMensage> : null}
            </InputDiv>
            {/* <ButtonVoice type='button' onClick={() => { setModalVoice(true) }} >
                <TiMicrophone
                  title="Aperte para falar seu rm e sua Senha"
                  size={"2.5rem"}
                />
                <p>Aperte para falar seu rm e sua Senha</p>
              </ButtonVoice> */}
            {rm.length > 0 && senha.length > 0 ? (
              <ReCAPTCHA
                Theme={'dark'}
                size={"compact"}
                sitekey={
                  window.location.href.includes("localhost") || window.location.href.includes("10")
                    || window.location.href.includes("127") || window.location.href.includes("testecart")
                    ? LOCAL_reCAPTCHA : PROD_reCAPTCHA
                }
                onChange={(Val) => setCapt(Val)}
              />
            ) : null}
            <Forget
              onClick={() => {
                setShow(true)
              }}
            >
              <p style={{ whiteSpace: "nowrap" }}>Esqueci minha senha</p>
              <Icon />
            </Forget>
          </FieldResponsive>
          <ButtonsResponsive>
            {capt2 ? <ErroMensage>Resolva o Captcha</ErroMensage> : null}
            <Button
              id={"button"}
              whitespace="nowrap"
              width="8rem"
              type={"submit"}
              height="100%"
              placeholder="Entrar"
            />
          </ButtonsResponsive>
        </FormResponsive>
        <p className="update">Ultíma Atualização: {UpdateData}</p>
      </ContainerResponsive>
    )
  } else {
    return (    // VISÃO DE PC --------------- VISÃO DE PC --------------- VISÃO DE PC --------------- VISÃO DE PC
      <>
        {/* <ModalVoiceLogin modalVoice={modalVoice} setModalVoice={setModalVoice} rm={rm} setRm={setRm} senha={senha} setSenha={setSenha} /> */}
        <EsqueciSenhaModal Show={Show} setShow={setShow} />
        <ComoAcessar showComoAcessar={showComoAcessar} setShowComoAcessar={setShowComoAcessar} />
        <EscolhaAcesso
          showEscolha={showEscolha} setShowEscolha={setShowEscolha}
          multipleAlunos={multipleAlunos} setMultipleAlunos={setMultipleAlunos}
          handleAlunoSelect={handleAlunoSelection}
        />
        <Card><img style={{ width: "160%", height: "110%" }} src={idCardImage} alt={"Imagem do Login"} /></Card>
        <Container>
          <Form onSubmit={(e) => { e.preventDefault(), HandleSubmit(e) }}>
            <Heading>
              Acessar Carteirinha
              <p className="ComoAcessar" onClick={() => setShowComoAcessar(true)}>Primeiro Acesso <FaRegQuestionCircle /></p>
            </Heading>
            <Field>
              <InputDiv>
                <div style={{ display: "flex", alignItems: "end" }}>
                  <Input
                    value={rm ? rm : ""}
                    placeholdercolor="var(--azul)"
                    color="var(--azul)"
                    type={"text"}
                    placeholder={"RM"}
                    required={"true"}
                    event={(e) => setRm(e.target.value)}
                  />
                </div>
                {rm2 ? <ErroMensage>Campo de RM vazio</ErroMensage> : null}
              </InputDiv>
              <InputDiv>
                <div style={{ display: "flex", alignItems: "end" }}>
                  <Input
                    value={senha ? senha : ""}
                    color="var(--azul)"
                    placeholdercolor="var(--azul)"
                    type={"password"}
                    placeholder={"Senha"}
                    required={"true"}
                    event={(e) => setSenha(e.target.value)}
                  />
                </div>
                {senha2 ? <ErroMensage>Campo de Senha vazia</ErroMensage> : null}
              </InputDiv>
              {/* <ButtonVoice type='button' onClick={() => { setModalVoice(true) }} >
                <TiMicrophone
                  title="Aperte para falar seu rm e sua Senha"
                  size={"2.5rem"}
                  />
                  <p>Aperte para falar seu rm e sua Senha</p>
                  </ButtonVoice> */}
              {rm.length > 0 && senha.length > 0 ? (
                <ReCAPTCHA
                  sitekey={
                    window.location.href.includes("localhost") || window.location.href.includes("10")
                      || window.location.href.includes("127") || window.location.href.includes("testecart")
                      ? LOCAL_reCAPTCHA : PROD_reCAPTCHA
                  }
                  size={range < 1300 ? "compact" : "normal"}
                  onChange={(Val) => setCapt(Val)}
                />
              ) : null}
              <Forget
                onClick={() => {
                  setShow(true)
                }}
              >
                <p style={{ whiteSpace: "nowrap" }}>Esqueci minha senha</p>
                <Icon />
              </Forget>
              <ButtonsResponsive2>
                {capt2 ? <ErroMensage>Resolva o Captcha</ErroMensage> : null}
                <Button
                  id={"button"}
                  whitespace="nowrap"
                  width="50%"
                  type={"submit"}
                  height="50px"
                  placeholder="Entrar"
                />
              </ButtonsResponsive2>
            </Field>
          </Form>
          <p className="update">Ultíma Atualização: {UpdateData}</p>
        </Container>
      </>
    )
  }
}
