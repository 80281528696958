import React, { useEffect, useState } from "react"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import TEXTS from "./assets/json/texts.json"
import { Authenticator, AuthenticatorAdmin } from "./auth"

// Components
import Navbar from "./Components/Navbar"
import Globalstyles from "./globalstyles"

// Pages
import Login from "./Pages/Login"
import HomeAluno from "./Pages/HomeAluno"
import HomeSecretaria from "./Pages/HomeSecretaria"
import NaoAutorizado from "./Pages/NaoAutorizado"
import CadastroAluno from "./Pages/CadastroAluno"
import DesativarAluno from "./Pages/DesativarAluno"
import GerarCarteirinhas from "./Pages/GerarCarteirinhas"
import VisualizarAlunos from "./Pages/VisualizarAlunos"
import VerCarterinhas from "./Pages/VerCarterinhas"
import VisualizarUsuarios from "./Pages/VisualizarUsuarios"
import AlunoSemFoto from "./Pages/AlunoSemFoto"
import VerCarterinha from "./Pages/VerCarterinha"
import DadosPublicos from "./Pages/DadosPublicos"
import AvisoManutencao from "./Pages/Manutencao"
import Loading from "./utils/Loading/Loading"

// Protected Route Components
const ProtectedRoute = ({ children, requireAdmin = false }) => {
  const isAuthenticated = requireAdmin ? AuthenticatorAdmin() : Authenticator()

  if (!isAuthenticated) {
    return <NaoAutorizado />
  }

  return children
}

const routes = () => {
  const [maintenanceStatus, setMaintenanceStatus] = useState({
    isActive: false,
    timeRemaining: { days: 0, hours: 0, minutes: 0, seconds: 0 },
  })

  const [firstAccess, setFirstAccess] = useState(false)

  useEffect(() => {
    // Check first access
    if (!!localStorage.getItem("Entrada") == true) {
      if (localStorage.getItem("Entrada") == "Informe uma senha para o aluno.") {
        setFirstAccess(true)
      } else {
        setFirstAccess(false)
      }
    }

    // Maintenance timer
    const calculateTimeRemaining = () => {
      const now = new Date()
      const targetDate = new Date(TEXTS.MAINTENANCE_END_DATE)

      if (now >= targetDate) {
        setMaintenanceStatus((prev) => ({ ...prev, isActive: false }))
        return
      }

      const total = targetDate.getTime() - now.getTime()
      const days = Math.floor(total / (1000 * 60 * 60 * 24))
      const hours = Math.floor((total % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = Math.floor((total % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((total % (1000 * 60)) / 1000)

      setMaintenanceStatus({
        isActive: true,
        timeRemaining: { days, hours, minutes, seconds },
      })
    }

    calculateTimeRemaining()
    const timer = setInterval(calculateTimeRemaining, 1000)
    return () => clearInterval(timer)
  }, [])

  if (maintenanceStatus.isActive) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<AvisoManutencao timeRemaining={maintenanceStatus.timeRemaining} />} />
        </Routes>
        <Globalstyles />
      </BrowserRouter>
    )
  }

  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        {/* Rotas Públicas */}
        <Route path="/" element={<Login />} />
        <Route path="/v/:id" element={<DadosPublicos />} />

        {/* Rota de ajuda externa */}
        <Route path="/Ajuda" element={<Navigate to="https://alunos.tawk.help" replace />} />

        {/* Rotas estudantis protegidas */}
        <Route
          path="/aluno"
          element={
            <ProtectedRoute>
              <HomeAluno first={firstAccess} />
            </ProtectedRoute>
          }
        />

        {/* Rotas administrativas protegidas */}
        <Route
          path="/secretaria"
          element={
            <ProtectedRoute requireAdmin>
              <HomeSecretaria />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vercarterinhasecretaria"
          element={
            <ProtectedRoute requireAdmin>
              <VerCarterinha />
            </ProtectedRoute>
          }
        />
        <Route
          path="/cadastroaluno"
          element={
            <ProtectedRoute requireAdmin>
              <CadastroAluno />
            </ProtectedRoute>
          }
        />
        <Route
          path="/desativaraluno"
          element={
            <ProtectedRoute requireAdmin>
              <DesativarAluno />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vercarterinhas"
          element={
            <ProtectedRoute requireAdmin>
              <VerCarterinhas />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gerarcarteirinhas"
          element={
            <ProtectedRoute requireAdmin>
              <GerarCarteirinhas />
            </ProtectedRoute>
          }
        />
        <Route
          path="/visualizaralunos"
          element={
            <ProtectedRoute requireAdmin>
              <VisualizarAlunos />
            </ProtectedRoute>
          }
        />
        <Route
          path="/visualizarusuarios"
          element={
            <ProtectedRoute requireAdmin>
              <VisualizarUsuarios />
            </ProtectedRoute>
          }
        />
        <Route
          path="/alunosemfoto"
          element={
            <ProtectedRoute requireAdmin>
              <AlunoSemFoto />
            </ProtectedRoute>
          }
        />

        {/* Pegue todas as rotas incomparáveis */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Globalstyles />
    </BrowserRouter>
  )
}

export default routes
